import axios from "axios";
import { FormSectionStepConfirmationRequestType } from "src/components/shared/types";
import { API_KEY, API_URL } from "src/constants";
import store from "src/store";
import { handleErrors } from "src/store/actions/auth";
import { Platform } from "./enums";
import {
  CompleteSignUpRequest,
  CreateGroupOnBoardingRequest,
  MemberSignUpFormDataValidation,
  SignAgreementType,
  GroupInvitationMember,
  ChatMessageRequest,
  SignAddMemberType,
  SignDeleteMemberType,
  SignUploadDocumentType,
  SectionStepCommentType,
  DocumentCommentType,
  UserInfoType,
  ChallengeResponseType,
  ChallengeRequestType,
  SignUpVerifyEmailData,
  SignUpVerifyPhoneData,
  SignUpVerifyGoogleData,
  SignUpSubmitPhoneCode,
  LoginVerifyEmail,
  LoginVerifyGoogle,
  SubmitEmailCode,
} from "./types";

const axiosClient = axios.create({
  baseURL: API_URL,
  headers: { "x-api-key": API_KEY },
  withCredentials: true,
});

axiosClient.interceptors.request.use((request) => {
  let headers = {
    ...request.headers,
    "X-CSRF-Token": document
      .getElementsByName("csrf-token")[0]
      .getAttribute("content"),
  };

  console.log("REQUEST " + request.url,request);
  return { ...request, headers };
});

axiosClient.interceptors.response.use(
  (response) => {
    console.log("RESPONSE",response.data);
    return response;
  },
  (error) => {
    store.dispatch(handleErrors(error?.response?.status));
    return Promise.reject(error.response ? error.response : error);
  }
);

// AUTHENTICATION
function getCSRFToken() {
  return axiosClient.get("");
}
// Sign up
function signUpVerifyEmail(data: SignUpVerifyEmailData) {
  return axiosClient.post("/auth/signup_verify_email", data);
}
function signUpVerifyGoogle(data: SignUpVerifyGoogleData) {
  return axiosClient.post(`/auth/signup_verify_email_google`, data);
}
function signUpVerifyPhone(data: SignUpVerifyPhoneData) {
  return axiosClient.post(`/auth/signup_verify_phone_number`, data);
}
function signUpSubmitEmailCode(
  emailChallengeId: string,
  data: SubmitEmailCode
) {
  return axiosClient.post(
    `/auth/signup_submit_verify_email/${emailChallengeId}`,
    data
  );
}
function signUpSubmitPhoneCode(
  phoneChallengeId: string,
  data: SignUpSubmitPhoneCode
) {
  return axiosClient.post(
    `/auth/signup_submit_verify_phone_number/${phoneChallengeId}`,
    data
  );
}
function completeSignUp(data: CompleteSignUpRequest) {
  return axiosClient.post(`/auth/complete_profile`, data);
}
function getUserData() {
  return axiosClient.get(`/auth/data`);
}
function validateReCaptcha(response: string) {
  return axiosClient.post(`/auth/recaptcha`, { response });
}
function validateIdentity(token: string) {
  return axiosClient.get(`/auth/validate_identity?t=${token}`);
}
function createAccessToken() {
  return axiosClient.get('/auth/sumsub/token');
}
function getApplicationStatus(applicantId: string) {
  return axiosClient.get(`/auth/sumsub/status/${applicantId}`);
}
// Log in
function loginVerifyEmail(data: LoginVerifyEmail) {
  return axiosClient.post("/auth/login_verify_email", data);
}
function loginVerifyGoogle(data: LoginVerifyGoogle) {
  return axiosClient.post(`/auth/login_verify_email_google`, data);
}
function loginSubmitEmailCode(emailChallengeId: string, data: SubmitEmailCode) {
  return axiosClient.post(
    `/auth/login_submit_verify_email/${emailChallengeId}`,
    data
  );
}
function logout() {
  return axiosClient.get(`/auth/logout`);
}

// GROUP CREATION
function getGroup() {
  return axiosClient.get(`group/data`);
}
function joinGroupByInvitation(invitationId: string) {
  return axiosClient.get(`group/join_group_by_invitation/${invitationId}`);
}
function createGroupOnboarding(data: CreateGroupOnBoardingRequest) {
  return axiosClient.post(`/group`, data);
}
function resendInvitation(invitationId: string) {
  return axiosClient.get(
    `/group/resend_invitation?invitationId=${invitationId}`
  );
}
function deleteInvitation(invitationId: string) {
  return axiosClient.get(
    `/group/delete_invitation?invitationId=${invitationId}`
  );
}
function validateClosingPurchaseDate(
  invitationId: string,
  closingPurchaseDate: string
) {
  return axiosClient.get(
    `/group/validate_closing_purchase_date?invitationId=${invitationId}&date=${closingPurchaseDate}`
  );
}
function validateJoinGroupMember(data: MemberSignUpFormDataValidation) {
  return axiosClient.post(`/group/validate_join_group_member`, data);
}
function sendVerificationReminder(userId: string) {
  return axiosClient.get(`/group/send_verification_reminder?userId=${userId}`);
}
function getInvitationMetadata(invitationId: string) {
  return axiosClient.get(`/group/invitation?invitationId=${invitationId}`);
}
function getNotificationStatus() {
  return axiosClient.get(`/group/notification_status`);
}
function accessDashboard() {
  return axiosClient.post(`/group/grant_dashboard_access`);
}

// GOVERNANCE
function getGovernanceSections() {
  return axiosClient.get(`/governance/sections`);
}
function getGovernanceStepByCode(code: string, currentStepCode?: string) {
  return currentStepCode
    ? axiosClient.get(
        `/governance/step?code=${code}&currentStepCode=${currentStepCode}`
      )
    : axiosClient.get(`/governance/step?code=${code}`);
}
function submitGovernanceFormsStep(
  data: FormSectionStepConfirmationRequestType
) {
  return axiosClient.post(`/governance/confirm`, data);
}
function getAgreementSectionsStatus() {
  return axiosClient.get(`/governance/co_agreement_section_status`);
}
function getAgreementStepsStatus(code: string) {
  return axiosClient.get(
    `/governance/co_agreement_step_status?sectionCode=${code}`
  );
}
function getPendingConsensusActions() {
  return axiosClient.get(`governance/pending_consensus_actions`);
}
function reviewAndSignAgreement(agreementCode: string) {
  return axiosClient.get(
    `governance/review_agreement_and_sign?agreementCode=${agreementCode}`
  );
}
function finalizeAgreement(agreementCode: string, fileHash: string) {
  return axiosClient.get(
    `/governance/finalize_agreement?agreementCode=${agreementCode}&fileHash=${fileHash}`
  );
}
function deleteGroupMember(userId: string) {
  return axiosClient.get(`/governance/delete_group_member?userId=${userId}`);
}
function addGroupMember(data: GroupInvitationMember) {
  return axiosClient.post(`/governance/add_group_member`, data);
}
function sendSignatureReminder(userId: string) {
  return axiosClient.get(
    `/governance/send_signature_reminder?userId=${userId}`
  );
}
function getSectionStepComments(
  sectionStepId: string,
  page: number,
  take: number
) {
  return axiosClient.get(
    `/governance/comment?sectionStepId=${sectionStepId}&page=${page}&take=${take}`
  );
}
function createSectionStepComment(data: SectionStepCommentType) {
  return axiosClient.post(`/governance/comment`, data);
}

// CHAT
function getChatMessages(page: number, take: number) {
  return axiosClient.get(`/chat?page=${page}&take=${take}`);
}
function getChatGroup() {
  return axiosClient.get(`/chat/info`);
}
function sendChatMessage(data: ChatMessageRequest) {
  return axiosClient.post(`/chat`, data);
}

// TRANSACTIONS
function signAgreement(data: SignAgreementType) {
  return axiosClient.post(`/txns/sign_agreement`, data);
}
function signAddMember(data: SignAddMemberType) {
  return axiosClient.post(`/txns/sign_add_member`, data);
}
function signDeleteMember(data: SignDeleteMemberType) {
  return axiosClient.post(`/txns/sign_delete_member`, data);
}
function startTxns() {
  return axiosClient.post(`/txns/start`);
}
function getTxnsProgress() {
  return axiosClient.get(`/txns/start_progress`);
}
function consentToUpload(data: SignUploadDocumentType) {
  return axiosClient.post(`/txns/sign_upload_document`, data);
}

function resetGroupAsaAppTxn() {
  return axiosClient.get(`/txns/group_asa_and_application`);
}

// DASHBOARD
function getDashboard() {
  return axiosClient.get(`/dashboard`);
}
function getNotifications(page: number, take: number) {
  return axiosClient.get(`/dashboard/notifications?page=${page}&take=${take}`);
}

// DOCUMENT VAULT
function getVault() {
  return axiosClient.get(`/vault`);
}
function getVaultDocument(id: string) {
  return axiosClient.get(`/vault/document/${id}`);
}
function downloadDocument(id: string) {
  return axiosClient.get(`/vault/download/${id}`, { responseType: "blob" });
}
function getVaultDocumentHistory(id: string, page: number, take: number) {
  return axiosClient.get(`vault/history/${id}?page=${page}&take=${take}`);
}
function getDocumentComments(id: string, page: number, take: number) {
  return axiosClient.get(
    `vault/document/${id}/comment?page=${page}&take=${take}`
  );
}
function createDocumentComment(data: DocumentCommentType) {
  return axiosClient.post(`vault/document/${data.documentId}/comment`, {
    comment: data.text,
  });
}
function deleteDocument(id: string) {
  return axiosClient.delete(`vault/document/${id}`);
}
function uploadDocument(data: FormData) {
  return axiosClient.post(`/vault`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

// PROFILE
function updateUserInfo(data: UserInfoType) {
  return axiosClient.patch(`user/info`, data);
}
function submitContactInfoUpdate(
  platform: Platform,
  data: ChallengeResponseType
) {
  return axiosClient.patch(`user/update/${platform}`, data);
}
function resendOTC(platform: Platform, data: ChallengeRequestType) {
  return axiosClient.post(`user/otp_resend/${platform}`, data);
}

// PAYMENT
function getSubscriptionLink() {
  return axiosClient.get(`subscription`);
}
function finishSubscription(checkoutId: string) {
  return axiosClient.post(`subscription/set_processing`, { checkoutId });
}
function unlockSubscriptionLink() {
  return axiosClient.delete(`subscription`);
}
function getGroupSubscriptionPlan(userToGroupId: string) {
  return axiosClient.get(`subscription/subscription_plan?userToGroupId=${userToGroupId}`);
}

const api = {
  finishSubscription,
  unlockSubscriptionLink,
  getSubscriptionLink,
  downloadDocument,
  resendOTC,
  submitContactInfoUpdate,
  updateUserInfo,
  createDocumentComment,
  getVault,
  getVaultDocument,
  getVaultDocumentHistory,
  getDocumentComments,
  deleteDocument,
  uploadDocument,
  consentToUpload,
  getChatGroup,
  accessDashboard,
  signAddMember,
  signDeleteMember,
  getNotifications,
  getDashboard,
  getChatMessages,
  sendChatMessage,
  startTxns,
  getTxnsProgress,
  getNotificationStatus,
  sendSignatureReminder,
  getGroup,
  reviewAndSignAgreement,
  finalizeAgreement,
  signAgreement,
  getSectionStepComments,
  createSectionStepComment,
  getInvitationMetadata,
  signUpSubmitPhoneCode,
  validateIdentity,
  loginVerifyGoogle,
  signUpVerifyGoogle,
  getCSRFToken,
  signUpVerifyEmail,
  signUpSubmitEmailCode,
  signUpVerifyPhone,
  completeSignUp,
  getUserData,
  getPendingConsensusActions,
  createGroupOnboarding,
  resendInvitation,
  deleteInvitation,
  validateClosingPurchaseDate,
  logout,
  loginSubmitEmailCode,
  loginVerifyEmail,
  validateJoinGroupMember,
  sendVerificationReminder,
  validateReCaptcha,
  joinGroupByInvitation,
  submitGovernanceFormsStep,
  getGovernanceStepByCode,
  getGovernanceSections,
  getAgreementSectionsStatus,
  getAgreementStepsStatus,
  deleteGroupMember,
  addGroupMember,
  getGroupSubscriptionPlan,
  resetGroupAsaAppTxn,
  createAccessToken,
  getApplicationStatus
};

export default api;
